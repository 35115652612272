.modal {
    top: 10% !important;
}

.modal-content {
    border-radius: 15px !important;
    padding: 35px;
    align-items: center;
}

.successModalContent {
    height: 310px;
    margin-top: 85px;
    background: #f7f7f7 !important;
    border-radius: 20px !important;
}

.modal-title {
    margin-bottom: -10px !important;
}

.modal-body {
    width: 100%;
    padding: 0 !important;
}

form {
    width: 100%;
}

@media (max-width: 768px) {
    .modal-dialog {
        max-width: 500px;
        margin: 2.5rem auto !important;
    }

    .modal-dialog.successModalDialog {
        padding: 0 20px !important;
    }

    .modal-backdrop.show {
        position: static !important;
        width: auto !important;
        height: auto !important;
        background-color: unset !important;
    }

    .modal {
        position: static !important;
    }

    .modal-content {
        border: none !important;
        background-color: unset !important;
        padding: 0 15px !important;
        min-width: 315px !important;
        margin: 0 auto !important;
    }

    .modal-content.deleteAccountContent {
        background: #f7f7f7 !important;
        /* margin-top: 70px !important; */
        min-width: 280px !important;
        margin: unset !important;
    }

    .modal-content.successModalContent {
        background: #f7f7f7 !important;
        margin-top: 70px !important;
        padding: 0 15px !important;
        min-width: 280px !important;
    }

    .modal-backdrop.show.deleteAccountBackdrop {
        position: fixed !important;
        width: 100vw !important;
        height: 100vh !important;
        background-color: #000 !important;
    }

    .deleteAccountModal.modal {
        position: fixed !important;
        padding-right: 0 !important;
    }

    .deleteAccountContent.modal-content {
        position: relative !important;
        display: flex !important;
        flex-direction: column !important;
        pointer-events: auto !important;
        background-color: #fff !important;
        background-clip: padding-box !important;
        border: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-radius: 0.3rem !important;
        outline: 0 !important;
        position: relative !important;
        border-radius: 15px !important;
        border-top-left-radius: 15px !important;
        border-top-right-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
        border-bottom-left-radius: 15px !important;
        padding: 30px !important;
    }

    .modal-backdrop.show {
        opacity: 0.5;
    }

    .modal-backdrop.fade {
        opacity: 0.5;
    }

    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1040;
        width: 100vw;
        height: 100vh;
        background-color: #000;
    }

    @media (max-width: 600px) {
        .deleteAccountContent.modal-content {
            width: 420px !important;
        }
    }
    @media (min-width: 576px) {
        .modal-dialog {
            margin: 2.5rem auto !important;
        }
    }
    @media (max-width: 520px) {
        .modal-dialog {
            max-width: 450px;
        }

        .deleteAccountContent.modal-content {
            padding: 30px;
        }
    }

    @media (max-width: 470px) {
        .deleteAccountContent.modal-content {
            width: 85% !important;
        }

        .modal-title {
            margin: 15px 0 !important;
        }

        .modal-header .close {
            padding-right: 0 !important;
            position: absolute !important;
            right: 15px !important;
            top: 10px;
            margin: 0 !important;
        }
    }

    @media (max-width: 460px) {
        .deleteAccountContent.modal-content {
            width: 95% !important;
        }
    }

    @media (max-width: 410px) {
        .deleteAccountContent.modal-content {
            width: 95% !important;
        }
    }

    @media (max-width: 375px) {
        .deleteAccountContent.modal-content {
            width: 100% !important;
        }
    }
}

@media (max-width: 530px) {
    .successModalContent {
        margin: 85px auto 0;
    }
}

@media (max-width: 510px) {
    .deleteAccountContent.modal-content {
        width: 85% !important;
    }
}

@media (max-width: 375px) {
    .modal-content {
        margin-top: 70px !important;
    }

    .deleteAccountContent.modal-content {
        padding: 30px;
    }
}

@media (min-height: 320px) and (max-width: 568px) {
    .deleteAccountContent.modal-content {
        height: 276px !important;
    }
}

@media (max-width: 280px) {
    .modal-content {
        min-width: 0 !important;
    }
}
